<template>
	<v-col cols="12" sm="12" md="12" lg="10" xl="10" class="mx-auto pt-0">
		<v-row class="mx-0 my-2 sub-title-1"> 
			<v-col>{{ $t('Cart.your_cart') }}</v-col>
		</v-row>
		<v-row class="mx-0 my-2">
			<v-col cols="12" md="" class="py-0">
				<v-row class="elevation-2 cell rounded-lg mx-0 pa-0 overflow-hidden" >
					<v-col class="py-0">
						<v-row class="text-center label--text font-weight-bold my-2 sub-title-2">
							<v-col>{{ $tc('global.name.name', 1) }}</v-col>
							<v-col>{{ $tc('global.product.price', 1) }}</v-col>
							<v-col>{{ $tc('global.product.quantity', 1) }}</v-col>
							<v-col>{{ $tc('global.product.subtotal', 1) }}</v-col>
							<v-col>{{ $tc('global.name.extension', 2) }}</v-col>
							<v-col cols="auto" style="width: 58px"></v-col>
						</v-row>
						<template v-if="cart_products" v-for="(product,i) in cart_products">
							<v-row :key="i" align='center' :class="i % 2 === 0 ? 'cell2':''" class="text-center paragraph">
								<v-col>{{ product.product.display_name}}</v-col>
								<v-col v-html="$options.filters.formatPrice(product.product.price, '€')"></v-col>
								<v-col>
									<v-row align="center" justify="center">
										<v-btn :disabled="product.count === 1" icon small @click="changeProductCount(product, product.id, product, false, false)">
											<v-icon small>mdi-minus</v-icon>
										</v-btn>
										x {{ product.count }}
										<v-btn :disabled="product.count === 10" icon small @click="changeProductCount(product, product.id, product, false, true)">
											<v-icon small>mdi-plus</v-icon>
										</v-btn>
									</v-row>
									
								</v-col>
								<v-col class="labelprimary--text font-weight-bold" v-html="$options.filters.formatPrice(product.count * product.product.price + totalExtensionProduct(product), '€')"></v-col>
								<v-col>
									<v-row justify="center" align="center">
										<div class="mr-2">{{ extensionProductNumber(product) }}</div>
										<v-btn v-if="product.extensions ? product.extensions.length > 0 : false" small icon @click="expanded.splice(i, 1, !expanded[i])"><v-icon :class="expanded[i] ? 'transition-rotate' : ''">mdi-chevron-down</v-icon></v-btn>
									</v-row>
								</v-col>
								<v-col cols="auto">
									<v-btn x-small class="rounded-lg" height="30" style="min-width: 32px; max-width: 32px" color="cellbackground" @click="deleteItem(product.id, i)"><v-icon size="16" >mdi-delete</v-icon></v-btn>
								</v-col>
							</v-row>
							<template v-for="ext in product.extensions">
								<v-expand-transition :key="ext.id" v-if="product.extensions">
									<v-row v-show="expanded[i]" align='center' :class="[i % 2 == 0 ? 'cell2':'', ext.count === 0 ? 'label--text':'']" class="text-center caption-1 ">
										<v-col>{{ ext.extension.display_name}}</v-col>
										<v-col v-html="$options.filters.formatPrice(ext.extension.price, '€')"></v-col>
										<v-col>
											<v-row align="center" justify="center">
												<v-btn :disabled="ext.count === 1 || ext.count === 0" icon small v-if="!ext.extension.uniq" @click="changeProductCount(product, product.id, ext, true, false)">
													<v-icon small>mdi-minus</v-icon>
												</v-btn>
												<div>x {{ ext.count }}</div>
												<v-btn :disabled="ext.count === 10 || ext.count === 0" icon small v-if="!ext.extension.uniq" @click="changeProductCount(product, product.id, ext, true, true)">
													<v-icon small>mdi-plus</v-icon>
												</v-btn>
											</v-row>
										</v-col>
										<v-col class="labelprimary--text font-weight-bold" v-html="$options.filters.formatPrice(ext.count * ext.extension.price, '€')"></v-col>
										<v-col></v-col>
										<v-col cols="auto" style="width: 56px">
											<v-btn small icon @click="addRemoveExt(ext.count === 0, ext, product)" :color="ext.count > 0 ? 'primary':''">
												<v-icon size="20">{{ ext.count > 0 ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</v-expand-transition>
							</template>
						</template>
					</v-col>
				</v-row>

				<v-row class="mx-0">
					<v-col cols="auto" class="pl-0 pr-2">
						<Button
								iconVal="mdi-arrow-left"
								color="celldark"
								dark
								:iconPosition="'left'"
								medium
								iconSmall
								:label="$tc('Cart.btn_market', 1)"
								:click="() => $router.push('/marketplace')"
						/>
					</v-col>
					<v-col cols="auto" class="pr-0 pl-2">
						<Button
								iconVal="mdi-cart-remove"
								color="secondary"
								:iconPosition="'left'"
								medium
								iconSmall
								:label="$tc('Cart.empty_cart', 1)"
								:click="() => confirm_dialog = true"
						/>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12" md="" :style="$vuetify.breakpoint.width > 960 ? {maxWidth: '340px'} : {}" class="py-0" >
				<v-row class="mx-0 cell rounded-lg elevation-2" align="center">
					<v-col class="py-1">
						<v-row class="mx-0" align="center">
							<v-col class="sub-title-2">Total :</v-col>
							<v-col>
								<div class="title-2 font-weight-bold labelprimary--text text-no-wrap text-right" v-html="$options.filters.formatPrice(getTotalCartPrice, '€')"></div>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" class="pt-0">
						<Button
								:disabled="cart_products.length === 0"
								block
								iconVal="mdi-cart-check"
								color="primary"
								:iconPosition="'left'"
								large
								iconSmall
								:label="$tc('navbar.Cart.checkout', 1)"
								:click="contact_id ? () => $router.push('/marketplace/checkout') : () => {
									dialog = true;
									old_redirect = $store.getters['redirect']
									$store.commit('CHANGE_REDIRECT_VAL', '/marketplace/checkout')
								}"
						/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<Dialog
				v-model="confirm_dialog"
				type="confirm"
				:message=" $tc('single_trad.Table.dialog_v_delete', 2)"
				@cancel="confirm_dialog = false;"
				@confirm="() => deleteItems()"
		/>

		<Dialog
				v-model="dialog"
				width="320"
				@close="dialog = false; $store.commit('CHANGE_REDIRECT_VAL', old_redirect)"
		>
			<template v-slot:content>
				<div style="position: relative; min-height: 480px">
					<transition name="slide-fade">
						<Login
								v-show="step === 0"
								class="pb-4"
								embed
								@register="step = 1"
								@close="dialog = false; $store.commit('CHANGE_REDIRECT_VAL', old_redirect)"
						/>
					</transition>
					<transition name="slide-fade">
						<Register
								v-show="step === 1"
								class="pb-4"
								embed
								@login="step = 0"
								@close="dialog = false; $store.commit('CHANGE_REDIRECT_VAL', old_redirect)"
						/>
					</transition>
				</div>
			</template>
		</Dialog>
	</v-col>
</template>

<script>
	export default {
		name: "Cart",
		components: {
			Button: () => import('@/components/ui/Button.vue'),
			Login: () => import('@/components/auth/Login.vue'),
			Register: () => import('@/components/auth/Register.vue'),
			Dialog: () => import('@/components/ui/Dialog.vue')
		},
		    data(){
		        return {
					expanded: [],
		            ready: false,
					cart_products: [],
					confirm_dialog: false,
			        dialog: false,
			        step: 0,
			        old_redirect: ''
		        }
	    },
	    computed: {
	        getTotalCartPrice(){
	            let total = 0

	            this.cart_products.forEach(ele => {
	                total += ele.count * (this.totalExtensionProduct(ele) + ele.product.price)
	            })

	            return total
	        },
		    contact_id(){
	        	return this.$store.getters['auth/contact_id']
		    }
		},
	    methods: {
	        addRemoveExt(e, ext, product){
	            if(e){
	                ext.count = 1
	            }
	            else {
	                ext.count = 0
	            }

	            this.$wsc.patchItem('cart/' + product.id + '/extension', ext.id, ext, success => {
	                this.$store.dispatch('snackbar/success', this.$t('snackbar.Cart.addRemoveExt.success'))
	            }, fail => {
	                this.$store.dispatch('snackbar/error', this.$t('snackbar.Cart.addRemoveExt.fail'))
	            })
	        },
	        totalExtensionProduct(product){
	            let res = 0

	            if(product.extensions) {
	                product.extensions.forEach(e => {
	                    res += e.count * e.extension.price
	                })
	            }

	            return res
	        },
	        extensionProductNumber(product){
	            let res = 0

	            if(product.extensions) {
	                product.extensions.forEach(e => {
	                    if(e.count > 0)
	                        res++
	                })
	            }

	            return res
	        },
	        deleteItem(id, index){
	            this.$wsc.deleteItem('cart', id, success => {
					this.cart_products.splice(index, 1)
					this.$store.dispatch('snackbar/success', this.$t('snackbar.Cart.deleteItem.success'))
					this.confirm_dialog = false
	            }, fail =>{
					this.$store.dispatch('snackbar/error', this.$t('snackbar.Cart.deleteItem.fail'))
					this.confirm_dialog = false
	            })
	        },
	        deleteItems(){
	            let ids = []
	            this.cart_products.forEach(p => {
	                ids.push(p.id)
	            })
	            this.$wsc.deleteBatch('cart', ids, success => {
					this.cart_products.splice(0, this.cart_products.lenght)
					this.$store.dispatch('snackbar/success', this.$t('snackbar.Cart.deleteItems.success'))
	            }, fail =>{
					this.$store.dispatch('snackbar/success', this.$t('snackbar.Cart.deleteItems.fail'))
	            })
	        },
	        changeProductCount(product, id, val, extension, plus){
	            if(plus){
	                if(val.count < 10){
	                    if(extension){
	                        this.$wsc.patchItem('cart/' + product.id + '/extension', val.id, {count: val.count+1}, success => {
	                            val.count++
	                        }, fail => {

	                        })
	                    }
	                    else {
	                        this.$wsc.patchItem('cart', product.id, {count: val.count+1}, success => {
	                            val.count++
	                        }, fail => {

	                        })
	                    }
	                }
	            }
	            else {
	                if(val.count > 0){
	                    if(extension){
	                        this.$wsc.patchItem('cart/' + product.id + '/extension', val.id, {count: val.count-1}, success => {
	                            val.count--
	                        }, fail => {

	                        })
	                    }
	                    else {
	                        this.$wsc.patchItem('cart', product.id, {count: val.count-1}, success => {
	                            val.count--
	                        }, fail => {

	                        })
	                    }
	                }
	            }
	        },
		},
		created() {
			this.$wsc.getList('cart', {}, products => {
				if(products.length === 0){
					if(this.$store.getters['redirect']) {
						this.$router.go(-1)
					}
					else {
						this.$router.push(this.$store.getters['marketplace_url'])
					}
				}

				products.forEach(e => {
					this.expanded.push(false)

					this.$wsc.getList('cart/' + e.id + '/extension', {}, ext => {
						this.$set(e, 'extensions', ext)
						this.cart_products = products
						this.ready = true
					}, fail => {

					})
				})

				this.$store.commit('cart/CHANGE_PRODUCTS_VAL', products)
			}, fail => {

			})
		}

	}
</script>

<style scoped>

</style>